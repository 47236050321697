export const paths = [
  'polygon(50% 50%, 100% 50%, 100% 0%, 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%)',
  'polygon(50% 50%, 100% 50%, 100% 0%, 100% 0%, 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%)',
  'polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%)',
  'polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 50% 0%, 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%)',
  'polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%, 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%)',
  'polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%, 0% 0%, 50% 50%, 50% 50%, 50% 50%, 50% 50%)',
  'polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%, 0% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%)',
  'polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%, 0% 50%, 0% 50%, 50% 50%, 50% 50%, 50% 50%)',
  'polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%, 0% 50%, 0% 100%, 50% 50%, 50% 50%, 50% 50%)',
  'polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%, 0% 50%, 0% 100%, 0% 100%, 50% 50%, 50% 50%)',
  'polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%, 0% 50%, 0% 100%, 50% 100%, 50% 50%, 50% 50%)',
  'polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%, 0% 50%, 0% 100%, 50% 100%, 50% 100%, 50% 50%)',
  'polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%, 0% 50%, 0% 100%, 50% 100%, 100% 100%, 50% 50%)',
  'polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%, 0% 50%, 0% 100%, 50% 100%, 100% 100%, 100% 100%)',
  'polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%, 0% 50%, 0% 100%, 50% 100%, 100% 100%, 100% 50%)'
]